import {
  ShippingAutoOrderStateEnum,
  ShippingDeliveryBasisEnum,
  ShippingDeliveryTypeEnum,
  ShippingPositionNamingEnum,
  ShippingStateEnum,
  WarehouseInstructionEnum,
} from '__generated__/types';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { IIndexable } from 'interfaces/stores/Shipping';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export enum ShippingDirection {
  C2P = 'c2p',
  C2X = 'c2x',
  P2C = 'p2c',
  P2P = 'p2p',
  P2X = 'p2x',
  X2C = 'x2c',
  X2P = 'x2p',
}

export enum ShippingSource {
  Customer = 'customer',
  Provider = 'provider',
  Xometry = 'xometry',
}

export enum ShippingProcessingStatus {
  // ...2X
  NotProcessed = 'not_processed',
  InProcessing = 'in_processing',
  PartiallyProcessed = 'partially_processed',
  ProcessedForRepack = 'processed_for_repack',
  ProcessedForReturn = 'processed_for_return',
  ProcessedForStorage = 'processed_for_storage',
  ProcessedAndSplit = 'processed_and_split',

  // ...2C
  Accepted = 'accepted',
  Claim = 'claim',

  // ...2P
  ReceivedSuccessfully = 'received_successfully',
}

export enum ShippingState {
  PmDraft = 'pm_draft',
  Packing = 'packing',
  Draft = 'draft',
  BoxReady = 'box_ready',
  ShippingOrdered = 'shipping_ordered',
  ReadyForShipping = 'ready_for_shipping',
  InTransit = 'in_transit',
  Delivered = 'delivered',
  Canceled = 'canceled',
}

export const autoOrderLockedStates = [
  ShippingAutoOrderStateEnum.NotChecked,
  ShippingAutoOrderStateEnum.WaitsForOrder,
  ShippingAutoOrderStateEnum.Ordered,
];

export const shippingPositionNamingLabels: Record<ShippingPositionNamingEnum, string> = {
  [ShippingPositionNamingEnum.Customs]: 'Customs',
  [ShippingPositionNamingEnum.Clients]: 'Clients',
  [ShippingPositionNamingEnum.CustomsAndClients]: 'Customs & Clients',
};

export const deliveryBasis = Object.values(ShippingDeliveryBasisEnum).map((value) => ({
  value,
  label: value,
}));

export const deliveryTypes = Object.values(ShippingDeliveryTypeEnum).map((value) => ({
  value,
  label: capitalizeFirstLetter(value),
}));

export const logisticAggregators = [
  {
    value: 'eurosender',
    label: 'Eurosender',
  },
  {
    value: 'packlink',
    label: 'PackLink',
  },
  {
    value: 'jumingo',
    label: 'Jumingo',
  },
  {
    value: 'direct',
    label: 'Direct',
  },
  {
    value: 'transglobalexpress',
    label: 'TransGlobalExpress',
  },
];

// Top 5 elements are ordered by usage frequency,
// the rest are sorted alphabetically
const logisticOperatorsByRegion: Record<Regions, { value: string; label: string }[]> = {
  [Regions.EU]: [
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'DPD',
      label: 'DPD',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'FedEx',
      label: 'FedEx',
    },
    {
      value: 'Schenker',
      label: 'Schenker',
    },
    {
      value: 'GLS',
      label: 'GLS',
    },
    {
      value: 'CargoInternational',
      label: 'CargoInternational',
    },
    {
      value: 'Deutsche Post',
      label: 'Deutsche Post',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'Euro Bus',
      label: 'Euro Bus',
    },
    {
      value: 'GEL',
      label: 'GEL',
    },
    {
      value: 'Go Express',
      label: 'Go Express',
    },
    {
      value: 'Hermes',
      label: 'Hermes',
    },
    {
      value: 'Jumingo Cargo',
      label: 'Jumingo Cargo',
    },
    {
      value: 'Jumingo same day',
      label: 'Jumingo same day',
    },
    {
      value: 'Kuehne + Nagel',
      label: 'Kuehne + Nagel',
    },
    {
      value: 'LKW',
      label: 'LKW',
    },
    {
      value: 'DeutschMann',
      label: 'DeutschMann',
    },
    {
      value: 'DTDC Express',
      label: 'DTDC Express',
    },
    {
      value: 'Helder',
      label: 'Helder',
    },
    {
      value: 'Majevski',
      label: 'Majevski',
    },
    {
      value: 'СlickTrans',
      label: 'СlickTrans',
    },
    {
      value: 'Aramex',
      label: 'Aramex',
    },
    {
      value: 'Meest',
      label: 'Meest',
    },
    {
      value: 'Air China Cargo',
      label: 'Air China Cargo',
    },
    {
      value: 'DX',
      label: 'DX',
    },
    {
      value: 'DHL Parcel UK',
      label: 'DHL Parcel UK',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'Yucatan',
      label: 'Yucatan',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
  [Regions.TR]: [
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'HubTic',
      label: 'HubTic',
    },
    {
      value: 'Millalog',
      label: 'Millalog',
    },
    {
      value: 'Yurtiçi',
      label: 'Yurtiçi Kargo',
    },
    {
      value: 'Aras',
      label: 'Aras Kargo',
    },
    {
      value: 'Tem Kurye',
      label: 'Tem Kurye',
    },
    {
      value: 'Banabi Kurye',
      label: 'Banabi Kurye',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'DTDC Express',
      label: 'DTDC Express',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'EUROTRANS',
      label: 'EUROTRANS',
    },
    {
      value: 'Kurye (Courier)',
      label: 'Kurye (Courier)',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'MNG Kargo',
      label: 'MNG Kargo',
    },
    {
      value: 'Sürat Cargo',
      label: 'Sürat Cargo',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
  [Regions.UK]: [
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'DPD',
      label: 'DPD',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'FedEx',
      label: 'FedEx',
    },
    {
      value: 'Schenker',
      label: 'Schenker',
    },
    {
      value: 'GLS',
      label: 'GLS',
    },
    {
      value: 'CargoInternational',
      label: 'CargoInternational',
    },
    {
      value: 'Deutsche Post',
      label: 'Deutsche Post',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'DTDC Express',
      label: 'DTDC Express',
    },
    {
      value: 'Euro Bus',
      label: 'Euro Bus',
    },
    {
      value: 'GEL',
      label: 'GEL',
    },
    {
      value: 'Go Express',
      label: 'Go Express',
    },
    {
      value: 'Hermes',
      label: 'Hermes',
    },
    {
      value: 'Jumingo Cargo',
      label: 'Jumingo Cargo',
    },
    {
      value: 'Jumingo same day',
      label: 'Jumingo same day',
    },
    {
      value: 'Kuehne + Nagel',
      label: 'Kuehne + Nagel',
    },
    {
      value: 'LKW',
      label: 'LKW',
    },
    {
      value: 'DeutschMann',
      label: 'DeutschMann',
    },
    {
      value: 'Helder',
      label: 'Helder',
    },
    {
      value: 'Majevski',
      label: 'Majevski',
    },
    {
      value: 'СlickTrans',
      label: 'СlickTrans',
    },
    {
      value: 'Aramex',
      label: 'Aramex',
    },
    {
      value: 'Meest',
      label: 'Meest',
    },
    {
      value: 'SF Express',
      label: 'SF Express',
    },
    {
      value: 'Air China Cargo',
      label: 'Air China Cargo',
    },
    {
      value: 'DHL Parcel UK',
      label: 'DHL Parcel UK',
    },
    {
      value: 'DX',
      label: 'DX',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
};

export const logisticOperators = logisticOperatorsByRegion[CURRENT_REGION];

export const processingStatuses = [
  {
    value: ShippingProcessingStatus.NotProcessed,
    label: 'Not yet processed',
    directions: [
      ShippingDirection.C2P,
      ShippingDirection.C2X,
      ShippingDirection.P2C,
      ShippingDirection.P2P,
      ShippingDirection.P2X,
      ShippingDirection.X2C,
      ShippingDirection.X2P,
    ],
  },
  {
    value: ShippingProcessingStatus.InProcessing,
    label: 'In processing (on QC)',
    directions: [ShippingDirection.C2X, ShippingDirection.P2X],
  },
  {
    value: ShippingProcessingStatus.PartiallyProcessed,
    label: 'Partially processed (manual)',
    directions: [ShippingDirection.C2X, ShippingDirection.P2X],
  },
  {
    value: ShippingProcessingStatus.ProcessedForRepack,
    label: 'Processed for repack (X2C)',
    directions: [ShippingDirection.C2X, ShippingDirection.P2X],
  },
  {
    value: ShippingProcessingStatus.ProcessedForReturn,
    label: 'Processed for return (X2P)',
    directions: [ShippingDirection.C2X, ShippingDirection.P2X],
  },
  {
    value: ShippingProcessingStatus.ProcessedForStorage,
    label: 'Processed for Inventory / Util',
    directions: [ShippingDirection.C2X, ShippingDirection.P2X],
  },
  {
    value: ShippingProcessingStatus.ProcessedAndSplit,
    label: 'Processed & split',
    directions: [ShippingDirection.C2X, ShippingDirection.P2X],
  },
  {
    value: ShippingProcessingStatus.Accepted,
    label: 'Accepted',
    directions: [ShippingDirection.P2C, ShippingDirection.X2C],
  },
  {
    value: ShippingProcessingStatus.Claim,
    label: 'Claim',
    directions: [ShippingDirection.P2C, ShippingDirection.X2C],
  },
  {
    value: ShippingProcessingStatus.ReceivedSuccessfully,
    label: 'Received successfully',
    directions: [ShippingDirection.C2P, ShippingDirection.P2P, ShippingDirection.X2P],
  },
];

export const sourceDocumentTypes: IIndexable<string> = {
  PO: 'Deal::ProviderOrder',
  DL: 'Deal::Delivery',
  SH: 'Deal::Shipping',
};

export const nonDirectShippingDirections = [
  {
    value: ShippingDirection.P2X,
    label: 'Partner to Xometry',
  },
  {
    value: ShippingDirection.X2P,
    label: 'Xometry to Partner (repair)',
  },
  {
    value: ShippingDirection.C2X,
    label: 'Customer to Xometry (return)',
  },
  {
    value: ShippingDirection.X2C,
    label: 'Xometry to Customer',
  },
];

export const shippingDirections = [
  {
    value: ShippingDirection.C2P,
    label: 'Customer to Partner (direct return)',
  },
  {
    value: ShippingDirection.P2P,
    label: 'Partner to Partner',
  },
  {
    value: ShippingDirection.P2C,
    label: 'Partner to Customer (direct shipping)',
  },
  ...nonDirectShippingDirections,
];

export const shippingStatuses: Array<{ value: ShippingState; label: string }> = [
  {
    value: ShippingState.PmDraft,
    label: 'PM Draft',
  },
  {
    value: ShippingState.Packing,
    label: 'Packing',
  },
  {
    value: ShippingState.Draft,
    label: 'Draft',
  },
  {
    value: ShippingState.BoxReady,
    label: 'Box Ready',
  },
  {
    value: ShippingState.ShippingOrdered,
    label: 'Shipping Ordered',
  },
  {
    value: ShippingState.ReadyForShipping,
    label: 'Ready For Shipping',
  },
  {
    value: ShippingState.InTransit,
    label: 'In Transit',
  },
  {
    value: ShippingState.Delivered,
    label: 'Delivered',
  },
  {
    value: ShippingState.Canceled,
    label: 'Canceled',
  },
];

export const shippingStates: Array<{ value: ShippingStateEnum; label: string }> = [
  {
    value: ShippingStateEnum.PmDraft,
    label: 'PM Draft',
  },
  {
    value: ShippingStateEnum.Packing,
    label: 'Packing',
  },
  {
    value: ShippingStateEnum.Draft,
    label: 'Draft',
  },
  {
    value: ShippingStateEnum.BoxReady,
    label: 'Box Ready',
  },
  {
    value: ShippingStateEnum.ShippingOrdered,
    label: 'Shipping Ordered',
  },
  {
    value: ShippingStateEnum.ReadyForShipping,
    label: 'Ready For Shipping',
  },
  {
    value: ShippingStateEnum.InTransit,
    label: 'In Transit',
  },
  {
    value: ShippingStateEnum.Delivered,
    label: 'Delivered',
  },
  {
    value: ShippingStateEnum.Canceled,
    label: 'Canceled',
  },
];

export const shippingProcessingInstructions = [
  {
    value: WarehouseInstructionEnum.Repack,
    label: 'Repack for Customer',
  },
  {
    value: WarehouseInstructionEnum.Xqc,
    label: 'Xometry QC',
  },
  {
    value: WarehouseInstructionEnum.Store,
    label: 'Xometry Store',
  },
  {
    value: WarehouseInstructionEnum.Scrap,
    label: 'Xometry Scrap',
  },
  {
    value: WarehouseInstructionEnum.Repack2p,
    label: 'Repack for Provider',
  },
];

export enum ShippingHsCode {
  Confirmed = 'confirmed',
  Standard = 'standard',
}

export const shippingHsCodeLabels: Record<ShippingHsCode, string> = {
  [ShippingHsCode.Confirmed]: '🟢 Confirmed',
  [ShippingHsCode.Standard]: '🟡 History / Client input / Empty',
};

export const shippingHSCodeStateOptions = Object.values(ShippingHsCode).map((state) => ({
  value: state,
  label: shippingHsCodeLabels[state],
}));

export const customerObjectAllowedDirections = [
  ShippingDirection.P2C,
  ShippingDirection.P2X,
  ShippingDirection.P2P,
  ShippingDirection.C2P,
  ShippingDirection.C2X,
];
