import { WorkspacesRfqOffer } from '__generated__/types';
import { countriesOpts as countryOptions } from 'collections/countries';
import {
  dealPipedriveStatusOptions,
  dealPipelineOptions,
  dealProductionStageOptions,
  dealProductionStageTranslations,
  ProductionStageEnum,
} from 'collections/deal';
import { states as providerStateOptions } from 'collections/provider';
import {
  decisionOptions as rfqOfferDecisionOptions,
  responseOptions as rfqOfferResponseOptions,
} from 'collections/rfqOffers';
import { stateOptions as rfqStateOptions } from 'collections/rfqs';
import { NA, percent, valueOrNa } from 'components/utils/formatters';
import { ColumnsMap, ColumnVisibility } from 'components/Workspaces/collections';
import { prepareUserOptions } from 'components/Workspaces/utils';
import React from 'react';
import { GetUsersQuery } from 'utils/graphql/queries/__generated__/users';

import {
  dealIdDecorator,
  decisionStateDecorator,
  idDecorator,
  ResponseStateDecorator,
  rfqIdDecorator,
  rfqStateDecorator,
  synProviderDecorator,
} from './decorators';

interface GenerateColumnsArgs {
  users: GetUsersQuery['users'];
}

export const generateColumns = ({ users }: GenerateColumnsArgs): ColumnsMap<WorkspacesRfqOffer> => {
  const userOptions = prepareUserOptions(users);

  return {
    id: {
      align: 'left',
      enabled: true,
      title: 'RO: ID',
      decoratorSelectiveFields: ['rfqId', 'dealId'],
      decorator: idDecorator,
    },
    rfqId: {
      align: 'left',
      enabled: false,
      title: 'RFQ: ID',
      decoratorSelectiveFields: ['dealId', 'rfqId', 'rfqNumber'],
      decorator: rfqIdDecorator,
    },
    rfqOwnerId: {
      align: 'left',
      enabled: false,
      title: 'RFQ: owner',
      options: userOptions,
      decoratorSelectiveFields: ['rfqOwnerName'],
      decorator: ({ rfqOwnerName }) => rfqOwnerName,
    },
    rfqState: {
      align: 'center',
      enabled: false,
      title: 'RFQ: state',
      options: rfqStateOptions,
      decorator: rfqStateDecorator,
    },
    synProviderId: {
      synthetic: true,
      align: 'left',
      enabled: true,
      title: 'RO: partner',
      renderSelectiveFields: ['providerId', 'providerName', 'providerState', 'providerCountry'],
      render: synProviderDecorator,
    },
    providerRank: {
      align: 'center',
      enabled: true,
      title: 'PR: rating',
      decorator: (offer) => offer.providerRank || <>&mdash;</>,
    },
    responseState: {
      align: 'center',
      enabled: true,
      title: 'RO: response',
      sortBy: 'responseStateSorted',
      options: rfqOfferResponseOptions,
      decoratorSelectiveFields: ['responseState', 'attachedFiles'],
      decorator: ResponseStateDecorator,
    },
    decisionState: {
      align: 'center',
      enabled: true,
      title: 'RO: decision',
      options: rfqOfferDecisionOptions,
      decoratorSelectiveFields: ['decidedAt'],
      decorator: decisionStateDecorator,
    },
    isPreferred: {
      enabled: true,
      align: 'center',
      title: 'RO: is preferred',
    },
    updatedAt: {
      align: 'left',
      enabled: true,
      title: 'RO: updated at',
    },
    createdAt: {
      align: 'left',
      enabled: false,
      title: 'RO: created at',
    },
    decidedAt: {
      align: 'left',
      enabled: false,
      title: 'RO: decided at',
    },
    interestedAt: {
      align: 'left',
      enabled: false,
      title: 'RO: interested at',
    },
    respondedAt: {
      align: 'left',
      enabled: false,
      title: 'RO: responded at',
    },
    viewedAt: {
      align: 'left',
      enabled: false,
      title: 'RO: viewed at',
    },
    qualifiedViewedAt: {
      align: 'left',
      enabled: false,
      title: 'RO: qualified viewed at',
    },
    responseRefusalReason: {
      align: 'left',
      enabled: false,
      title: 'RO: response refusal reason',
    },
    providerId: {
      enabled: false,
      align: 'left',
      title: 'PR: ID',
    },
    providerName: {
      enabled: false,
      align: 'center',
      title: 'PR: name',
    },
    providerState: {
      enabled: false,
      align: 'center',
      title: 'PR: state',
      options: providerStateOptions,
    },
    providerTiming: {
      enabled: true,
      align: 'center',
      title: 'PR: timing',
      decoratorSelectiveFields: ['providerTimingRatio'],
      decorator: (offer) => (
        <>
          {valueOrNa(offer.providerTiming)}
          <br />
          {offer.providerTimingRatio ? percent(offer.providerTimingRatio * 100) : NA}
        </>
      ),
    },
    providerQuality: {
      enabled: true,
      align: 'center',
      title: 'PR: quality',
      decoratorSelectiveFields: ['providerClaimRatio'],
      decorator: (offer) => (
        <>
          {valueOrNa(offer.providerQuality)}
          <br />
          {offer.providerClaimRatio ? percent(offer.providerClaimRatio * 100) : NA}
        </>
      ),
    },
    providerTimingRatio: {
      enabled: false,
      align: 'left',
      title: 'PR: timing ratio',
    },
    providerCancellationRatio: {
      enabled: true,
      align: 'center',
      title: 'PR: cancel',
      decorator: (offer) => {
        const cancellationRatio = offer.providerCancellationRatio;

        return cancellationRatio !== null ? percent((cancellationRatio || 0) * 100) : NA;
      },
    },
    providerAne: {
      enabled: false,
      align: 'center',
      title: 'PR: ANE',
      decorator: (offer) => (offer.providerAne ? percent(offer.providerAne * 100) : NA),
    },
    providerDaysAwayFromPa: {
      enabled: true,
      align: 'center',
      title: 'PR: Days away from PA',
    },
    providerClaimRatio: {
      enabled: false,
      align: 'left',
      title: 'PR: claim ratio',
    },
    providerCountry: {
      enabled: false,
      align: 'center',
      title: 'PR: country',
      options: countryOptions,
    },
    dealId: {
      align: 'left',
      enabled: false,
      title: 'D: ID',
      decoratorSelectiveFields: ['dealId', 'dealNumber'],
      decorator: dealIdDecorator,
    },
    pipedriveStatus: {
      enabled: false,
      align: 'left',
      title: 'D: status',
      options: dealPipedriveStatusOptions,
    },
    pipedrivePipelineName: {
      enabled: false,
      align: 'left',
      title: 'D: pipeline',
      options: dealPipelineOptions,
    },
    productionStage: {
      enabled: false,
      align: 'left',
      title: 'D: Stage',
      sortBy: 'productionStageOrdered',
      options: dealProductionStageOptions,
      decoratorSelectiveFields: ['productionStageOrdered'],
      decorator: ({ productionStage }) =>
        productionStage && dealProductionStageTranslations[productionStage as ProductionStageEnum],
    },
    rfqOwnerName: {
      enabled: false,
      visibility: ColumnVisibility.Hidden,
    },
    responseStateSorted: {
      enabled: false,
      visibility: ColumnVisibility.Hidden,
    },
    dealNumber: {
      enabled: false,
      visibility: ColumnVisibility.Hidden,
    },
    rfqNumber: {
      enabled: false,
      visibility: ColumnVisibility.Hidden,
    },
  };
};
