import { DealPipedriveStatus } from 'interfaces';

export enum ProductionStageEnum {
  /** calculated */
  Calculated = 'calculated',
  /** draft */
  Draft = 'draft',
  /** need_calculate */
  NeedCalculate = 'need_calculate',
  /** pending */
  Pending = 'pending',
  /** prod_delivered */
  ProdDelivered = 'prod_delivered',
  /** prod_placed */
  ProdPlaced = 'prod_placed',
  /** prod_placing */
  ProdPlacing = 'prod_placing',
  /** prod_processing */
  ProdProcessing = 'prod_processing',
  /** prod_shipped2c */
  ProdShipped2c = 'prod_shipped2c',
  /** prod_shipped2x */
  ProdShipped2x = 'prod_shipped2x',
  /** prod_warehouse */
  ProdWarehouse = 'prod_warehouse',
  /** submitted */
  Submitted = 'submitted',
}

const dealPipelines = ['Instant quoting', 'Sales', 'IM Quoting', 'Backlog'];

export const dealPipelineOptions = dealPipelines.map((pipeline) => ({
  value: pipeline,
  label: pipeline,
}));

export const dealStagesById: Record<number, string> = {
  23: '1.1. Instant quoting / Pending',
  24: '1.2. Instant quoting / Need Calculation',
  25: '1.3. Instant quoting / Calculated',
  28: '1.4. Instant quoting / Waiting for prepay',
  1: '2.1. Sales / Request',
  19: '2.2. Sales / Clarification',
  2: '2.3. Sales / Qualified',
  4: '2.4. Sales / Quote sent',
  5: '2.5. Sales / Negotiating',
  13: '2.6. Sales / Parking',
  27: '2.7. Sales / Waiting for prepay',
  15: '3.1. IM / Enquiry received',
  34: '3.2. IM / Data needed',
  16: '3.3. IM / Checking design',
  17: '3.4. IM / Design comments',
  18: '3.5. IM / Excel quoting',
  29: '3.6. IM / Partner RFQ',
  30: '3.7. IM / Quote received',
  31: '3.8. IM / Quote sent',
  32: '3.9. IM / Negotiating',
  33: '3.10. IM / Parking',
  6: '4.1. Backlog / Order received',
  20: '4.2. Backlog / Processing',
  21: '4.3. Backlog / Outsourcing',
  8: '4.4. Backlog / Placed',
  11: '4.5. Backlog / Sent to us',
  10: '4.6. Backlog / Arrived',
  9: '4.7. Backlog / Sent to customer',
  12: '4.8. Backlog / Invoice overdue',
  14: '4.9. Backlog / Claim',
};

// TODO: Remove after merge workspaces deal list.
export const dealStageOptions = Object.values(dealStagesById).map((stage) => ({
  label: stage,
  value: stage,
}));

export const dealPipedriveStatusOptions = Object.values(DealPipedriveStatus).map((status) => ({
  value: status,
  label: status,
}));

/** Deal Pipedrive status */
export enum PipedriveStatusEnum {
  /** deleted */
  Deleted = 'deleted',
  /** lost */
  Lost = 'lost',
  /** open */
  Open = 'open',
  /** won */
  Won = 'won',
}

export const dealProductionStageTranslations: Record<ProductionStageEnum, string> = {
  [ProductionStageEnum.Draft]: 'Draft',
  [ProductionStageEnum.Pending]: 'Pending',
  [ProductionStageEnum.NeedCalculate]: 'Need Calculate',
  [ProductionStageEnum.Calculated]: 'Calculated',
  [ProductionStageEnum.Submitted]: 'Submitted',
  [ProductionStageEnum.ProdProcessing]: 'Processing',
  [ProductionStageEnum.ProdPlacing]: 'Placing',
  [ProductionStageEnum.ProdPlaced]: 'Placed',
  [ProductionStageEnum.ProdShipped2x]: 'Shipped 2X',
  [ProductionStageEnum.ProdWarehouse]: 'Warehouse',
  [ProductionStageEnum.ProdShipped2c]: 'Shipped 2C',
  [ProductionStageEnum.ProdDelivered]: 'Delivered',
};

export const dealProductionStageOptions = Object.values(ProductionStageEnum).map((value) => ({
  value,
  label: dealProductionStageTranslations[value],
}));
