import { ENV_APP_HOST_EU, ENV_BASE_URL } from 'constants/env';
import { ReactText } from 'react';

import { TypeRouteId } from './api';
import external from './external';
import lookers from './lookers';
import oms from './omsRoutes';
import partners from './partners';

const root = () => ENV_BASE_URL || '/';

const pOrgsPath = () => `${root()}o`;
const pOrgPath = (id: string) => `${pOrgsPath()}/${id}`;
const pOrgBalancePath = (id: string) => `${pOrgPath(id)}/balance`;
const orgsPath = () => `${root()}organizations`;
const orgPath = (id: TypeRouteId) => `${orgsPath()}/${id}`;
const foreignRegionOrgPath = (regionHost: string, id: TypeRouteId) => `${regionHost}/organizations/${id}`;
const newOrgPath = () => `${orgsPath()}/new`;
const orgBalancePath = (id: TypeRouteId) => `${orgPath(id)}/balance`;
const dashboardsPath = () => `${root()}dashboards/`;

const promotionsPath = () => `${root()}promotions`;
const newPromotionPath = () => `${promotionsPath()}/new`;
const editPromotionPath = (id: TypeRouteId) => `${promotionsPath()}/${id}/edit`;

const searchPagePath = (model: string) => `${root()}search/${model}`;

const pPersonsPath = () => `${root()}p`;
const pPersonPath = (id: string) => `${pPersonsPath()}/${id}`;
const personsPath = () => `${root()}persons`;
const personPath = (id: TypeRouteId) => `${personsPath()}/${id}`;
const foreignRegionPersonPath = (regionHost: string, id: TypeRouteId) => `${regionHost}/persons/${id}`;
const saPath = (personId: TypeRouteId, saId: TypeRouteId) => `${personPath(personId)}?SA=${saId}`;
const newPersonPath = () => `${personsPath()}/new`;

const providersPath = () => `${root()}providers`;
const providerPath = (id: TypeRouteId) => `${providersPath()}/${id}`;
const providerBalancePath = (id: TypeRouteId) => `${providerPath(id)}/balance`;

const billingAccountPath = (id: TypeRouteId) => `${root()}billing_accounts/${id}`;
const billingAccountBalancePath = (id: TypeRouteId) => `${billingAccountPath(id)}/balance`;

const shippingAddressPath = (id: TypeRouteId) => `${root()}shipping_addresses/${id}`;

const signInPath = () => `${root()}sign_in`;
const ssoCallbackPath = () => `${root()}sso/callback`;

const messageCenterPath = () => `${root()}message-center`;

const teamsPath = () => `${root()}teams`;
const teamPath = (id: TypeRouteId) => `${teamsPath()}/${id}`;

const dealsPath = () => `${root()}deals`;
const dealPath = (id: TypeRouteId) => `${dealsPath()}/${id}`;
const dealFrameworkAgreementPath = (id: TypeRouteId) => `${dealsPath()}/${id}/framework`;
const dealOverviewPath = (id: TypeRouteId) => `${dealsPath()}/${id}/overview`;
const dealQuotationPath = (dealId: ReactText, quotationId: ReactText) =>
  `${dealPath(dealId)}/quotations/${quotationId}`;

const enquiryPath = (dealId: TypeRouteId, id: TypeRouteId) => `${dealPath(dealId)}/enquiries/${id}`;
const enquiryEditPath = (dealId: TypeRouteId, id: TypeRouteId) => `${enquiryPath(dealId, id)}/edit`;
const newEnquirySendRfqPath = (dealId: TypeRouteId, id: TypeRouteId) => `${enquiryPath(dealId, id)}/send_rfq`;

const rfqEditPath = (dealId: TypeRouteId, id: TypeRouteId) => `${dealPath(dealId)}/rfqs/${id}/edit`;

const rfqsPath = (dealId?: string | number) => `${dealPath(dealId || ':dealId')}/rfqs`;
const rfqOfferPath = (dealId: TypeRouteId, rfqId: TypeRouteId, rfqOfferId: TypeRouteId) =>
  `${rfqsPath(dealId)}/${rfqId}/rfq_offers/${rfqOfferId}/edit`;

const oldRfqOfferPath = (dealId: TypeRouteId, rfqId: TypeRouteId, rfqOfferId: TypeRouteId) =>
  `${rfqsPath(dealId)}/${rfqId}/rfqOffers/${rfqOfferId}/edit`;

const pDealsPath = () => `${root()}d`;
const pDealPath = (id: TypeRouteId) => `${pDealsPath()}/${id}`;

const dealIncInvoicesPath = (dealId: TypeRouteId) => `${dealPath(dealId)}/incoming_invoices`;

const dealPartnerCreditNoteBasePath = (dealId: TypeRouteId) => `${dealPath(dealId)}/partner_credit_note`;
const dealPartnerCreditNotePath = (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
  `${dealPartnerCreditNoteBasePath(dealId)}/${invoiceId}`;

const dealPartnerCreditNoteCorrectionBasePath = (dealId: TypeRouteId) => `${dealPath(dealId)}/partner_cn_correction`;
const dealPartnerCreditNoteCorrectionPath = (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
  `${dealPartnerCreditNoteCorrectionBasePath(dealId)}/${invoiceId}`;

const outcomingInvoicePath = (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
  `${dealPath(dealId)}/outcoming_invoices/${invoiceId}`;
const incomingInvoicePath = (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
  `${dealIncInvoicesPath(dealId)}/${invoiceId}`;
const logisticInvoicesPath = () => `${root()}logistic_invoices`;
const logisticInvoicePath = (invoiceId: TypeRouteId) => `${logisticInvoicesPath()}/${invoiceId}`;
const paymentPath = (dealId: TypeRouteId, paymentId: TypeRouteId) => `${dealPath(dealId)}/payments/${paymentId}`;

const payoutsPath = (dealId: TypeRouteId) => `${dealPath(dealId)}/payouts`;
const payoutPath = (dealId: TypeRouteId, payoutId: TypeRouteId) => `${payoutsPath(dealId)}/${payoutId}`;

const dealQualityControlsPath = (dealId: TypeRouteId) => `${dealPath(dealId)}/quality_controls`;
const qualityControlPath = (dealId: TypeRouteId, qcId: TypeRouteId) => `${dealQualityControlsPath(dealId)}/${qcId}`;

const providerOrderPath = (dealId: TypeRouteId, providerOrderId: TypeRouteId) =>
  `${dealPath(dealId)}/provider_orders/${providerOrderId}`;

const jobsPath = (dealId?: string | number) => `${dealPath(dealId || ':dealId')}/jobs`;
const jobPath = (dealId: string | number, id: string | number) => `${jobsPath(dealId)}/${id}/edit`;
const newJobPath = (dealId: string | number) => `${jobsPath(dealId)}/new`;
const jobOfferPath = (dealId: TypeRouteId, jobId: TypeRouteId, id: TypeRouteId) =>
  `${jobsPath(dealId)}/${jobId}/offers/${id}/edit`;
const newJobOfferPath = (dealId: TypeRouteId, jobId: TypeRouteId) => `${jobsPath(dealId)}/${jobId}/offers/new`;
const newJobSendEmailPath = (dealId: string, jobId: string) => `${jobsPath(dealId)}/${jobId}/send_email`;

const shippingsPath = (dealId?: string | number) => `${dealPath(dealId || ':dealId')}/shippings`;
const newShippingPath = (dealId?: string | number) => `${shippingsPath(dealId)}/new`;
const editShippingPath = (dealId: TypeRouteId, shippingId: string | number, action?: string) =>
  `${shippingsPath(dealId)}/${shippingId}/edit${action != null ? `/${action}` : ''}`;

const masterShippingsPath = () => `${root()}master_shippings`;
const editMasterShippingPath = (shippingId: string | number, urlAction?: string) =>
  `${masterShippingsPath()}/${shippingId}/edit${urlAction != null ? `/${urlAction}` : ''}`;

const storagesPath = (dealId: ReactText) => `${dealPath(dealId)}/storages`;
const newStoragePath = (dealId: ReactText) => `${dealPath(dealId)}/storages/new`;
const editStoragePath = (dealId: ReactText, storageId: ReactText) => `${dealPath(dealId)}/storages/${storageId}/edit`;
const storageInventoryNotePath = (id: ReactText) => `${root()}storages/${id}/inventory_note`;

const claimsPath = (dealId: ReactText) => `${dealPath(dealId)}/claims`;
const newDealClaimPath = (dealId: ReactText) => `${claimsPath(dealId)}/new`;
const editDealClaimPath = (dealId: ReactText, claimId: ReactText) => `${claimsPath(dealId)}/${claimId}/edit`;

const deliveriesPath = (dealId: ReactText) => `${dealPath(dealId)}/deliveries`;
const deliveryPath = (dealId: ReactText, deliveryId: ReactText) => `${deliveriesPath(dealId)}/${deliveryId}/show`;

const recentLeadsReviewPath = () => `${root()}users/leads_review`;
const userProfilePath = (userId: ReactText) => `${root()}users/${userId}/profile`;

const rolesPath = () => `${root()}roles`;

const messageFiltersPath = () => `${root()}message_filters`;
const messageFiltersGroupPath = (groupId: string) => `${root()}message_filters/group/${groupId}`;

const templatesPlaceholdersPath = () => `${root()}messenger_templates_placeholders`;

const workspacesPath = () => `${root()}workspaces`;
const workspacesGeneralPath = () => `${workspacesPath()}/general`;
const workspacesGeneralClaimsPath = () => `${workspacesGeneralPath()}/claims`;
const workspacesGeneralEnquiriesPath = () => `${workspacesGeneralPath()}/quotes`;
const workspacesGeneralRfqsPath = () => `${workspacesGeneralPath()}/rfqs`;
const workspacesGeneralRfqOffersPath = () => `${workspacesGeneralPath()}/rfq_offers`;
const workspacesGeneralCouponsPath = () => `${workspacesGeneralPath()}/coupons`;
const workspacesGeneralJobsPath = () => `${workspacesGeneralPath()}/jobs`;
const workspacesGeneralJobOffersPath = () => `${workspacesGeneralPath()}/job_offers`;
const workspacesGeneralIncomingInvoicesPath = () => `${workspacesGeneralPath()}/incoming_invoices`;
const workspacesGeneralOutcomingInvoicesPath = () => `${workspacesGeneralPath()}/outcoming_invoices`;
const workspacesGeneralLogisticInvoicesPath = () => `${workspacesGeneralPath()}/logistic_invoices`;
const workspacesGeneralPartnersPath = () => `${workspacesGeneralPath()}/partners`;
const workspacesGeneralPersonsPath = () => `${workspacesGeneralPath()}/persons`;
const workspacesGeneralProviderOrdersPath = () => `${workspacesGeneralPath()}/production_orders`;
const workspacesGeneralProductionSchedulesPath = () => `${workspacesGeneralPath()}/production_schedules`;
const workspacesGeneralOrganizationsPath = () => `${workspacesGeneralPath()}/organizations`;
const workspacesGeneralDealsPath = () => `${workspacesGeneralPath()}/deals`;
const workspacesGeneralFrameworksPath = () => `${workspacesGeneralPath()}/frameworks`;
const workspacesGeneralQualityControlsPath = () => `${workspacesGeneralPath()}/quality_controls`;
const workspacesGeneralShippingsPath = () => `${workspacesGeneralPath()}/shippings`;
const workspacesGeneralStoragesPath = () => `${workspacesGeneralPath()}/storages`;
const workspacesGeneralBillingAccountsPath = () => `${workspacesGeneralPath()}/billing_accounts`;
const workspacesGeneralShippingAddressesPath = () => `${workspacesGeneralPath()}/shipping_addresses`;
const workspacesGeneralPaymentsPath = () => `${workspacesGeneralPath()}/payments`;
const workspacesGeneralPayoutsPath = () => `${workspacesGeneralPath()}/payouts`;
const workspacesGeneralRefundsPath = () => `${workspacesGeneralPath()}/refunds`;
const workspacesGeneralBankPaymentsPath = () => `${workspacesGeneralPath()}/bank_payments`;
const workspacesGeneralBankPaymentsDraftPath = () => `${workspacesGeneralPath()}/bank_payments_draft`;
const workspacesGeneralUsersPath = () => `${workspacesGeneralPath()}/users`;
const workspacesGeneralOrganizationBalancesPath = () => `${workspacesGeneralPath()}/organization_balances`;
const workspacesGeneralTasksPath = () => `${workspacesGeneralPath()}/tasks`;
const workspacesGeneralMessageSubscriptionsPath = () => `${workspacesGeneralPath()}/message_subscriptions`;
const workspacesGeneralMessagesPath = () => `${workspacesGeneralPath()}/messages`;
const workspacesGeneralEmailMessagesPath = () => `${workspacesGeneralPath()}/incoming_emails`;
const workspacesGeneralHsCodesPath = () => `${workspacesGeneralPath()}/hs_codes`;

const workspacesLogisticsPath = () => `${workspacesPath()}/logistics`;
const workspacesLogisticsShippingsPath = () => `${workspacesLogisticsPath()}/shippings`;

const workspacesRfqPath = () => `${workspacesPath()}/rfq`;
const workspacesRfqPartnersPath = () => `${workspacesRfqPath()}/partners`;

/** @deprecated use workspacesDrawingsPath */
const workspacesDraftingPath = () => `${workspacesPath()}/drafting`;
/** @deprecated use workspacesDrawingsOrdersPath instead */
const workspacesDraftingDealPartsPath = () => `${workspacesDraftingPath()}/deal_parts`;

const workspacesDrawingsPath = () => `${workspacesPath()}/drawings`;
const workspacesDrawingsOrdersPath = () => `${workspacesDrawingsPath()}/orders`;

/** @deprecated use workspacesDrawingsQuotesPath */
const workspacesDraftingEnquiryPartsPath = () => `${workspacesDraftingPath()}/enquiry_parts`;

const workspacesDrawingsQuotesPath = () => `${workspacesDrawingsPath()}/quotes`;

const bankPaymentsImportsPath = () => `${root()}bank_payments/imports`;
const bankPaymentsImportPath = (id: TypeRouteId) => `${bankPaymentsImportsPath()}/${id}`;
const bankPaymentsExportsPath = () => `${root()}bank_payments/exports`;
const bankPaymentsExportPath = (id: TypeRouteId) => `${bankPaymentsExportsPath()}/${id}`;

const PLACING_REPEAT_DEAL_PRESET_ID = 420;
const REPLACING_REQUEST_PRESET_ID = 421;
const PLACING_ISSUES_PRESET_ID = 422;

const placingRepeatDeal = () => `${routes.workspacesGeneralTasksPath()}?preset=${PLACING_REPEAT_DEAL_PRESET_ID}`;
const replacingRequest = () => `${routes.workspacesGeneralTasksPath()}?preset=${REPLACING_REQUEST_PRESET_ID}`;
const placingIssues = () => `${routes.workspacesGeneralTasksPath()}?preset=${PLACING_ISSUES_PRESET_ID}`;

const activityLogPath = (dealId: TypeRouteId) => `${dealPath(dealId)}/activity_log`;

export const routes = {
  oms,
  rootPath: () => `${root()}`,
  notFound: () => `${root()}404`,
  // dealNotFOund: (): string => `deals/not_found`,
  printerPath: () => `${root()}printer`,
  accountingExportsProvidersPath: () => `${root()}accounting/exports/providers`,
  accountingExportProvidersPath: (id: TypeRouteId) => `${root()}accounting/exports/providers/${id}`,
  accountingExportsBillingAccountsPath: () => `${root()}accounting/exports/billing_accounts`,
  accountingExportBillingAccountsPath: (id: TypeRouteId) => `${root()}accounting/exports/billing_accounts/${id}`,
  accountingExportOutcomingsPath: () => `${root()}accounting/exports/out`,
  accountingExportOutcomingPath: (id: TypeRouteId) => `${root()}accounting/exports/out/${id}`,
  accountingExportIncomingsPath: () => `${root()}accounting/exports/in`,
  accountingExportIncomingPath: (id: TypeRouteId) => `${root()}accounting/exports/in/${id}`,
  datevExportsPaymentsPath: () => `${root()}datev/exports/payments`,
  datevExportsPaymentAllocationsPath: () => `${root()}datev/exports/payment_allocations`,
  datevExportsPaymentAllocationPath: (id: TypeRouteId) => `${root()}datev/exports/payment_allocations/${id}`,
  datevExportBankPaymentsPath: () => `${root()}datev/exports/bank_payments}`,
  datevExportBankPaymentPath: (id: TypeRouteId) => `${root()}datev/exports/bank_payments/${id}`,
  datevExportPayoutsPath: (id: TypeRouteId) => `${root()}datev/exports/payouts/${id}`,
  datevExportPaymentsPath: (id: TypeRouteId) => `${root()}datev/exports/payments/${id}`,
  bankPaymentsImportsPath,
  bankPaymentsImportPath,
  bankPaymentsExportsPath,
  bankPaymentsExportPath,

  smartRouterPath: () => `${root()}links`,

  signInPath,
  ssoCallbackPath,

  messageCenterPath,
  templates: () => `${root()}templates`,

  dealsPath,
  dealPath,
  dealFrameworkAgreementPath,
  dealOverviewPath,

  enquiryPath,
  enquiryEditPath,
  newEnquirySendRfqPath,

  rfqEditPath,
  rfqOfferPath,
  oldRfqOfferPath,

  pDealsPath,
  pDealPath,

  pOrgsPath,
  pOrgPath,
  pOrgBalancePath,
  orgsPath,
  orgPath,
  newOrgPath,
  orgBalancePath,
  foreignRegionOrgPath,

  promotionsPath,
  newPromotionPath,
  editPromotionPath,

  searchPagePath,

  pPersonsPath,
  pPersonPath,
  personsPath,
  personPath,
  saPath,
  newPersonPath,
  foreignRegionPersonPath,

  jobsPath,
  jobPath,
  newJobPath,
  jobOfferPath,
  newJobOfferPath,
  newJobSendEmailPath,

  claimsPath,
  newDealClaimPath,
  editDealClaimPath,

  newShippingPath,
  editShippingPath,

  editMasterShippingPath,

  storagesPath,
  newStoragePath,
  editStoragePath,
  storageInventoryNotePath,

  deliveriesPath,
  deliveryPath,

  orderConfirmationPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/order_confirmation/`,

  dealPartsPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/parts`,
  dealPartPath: (dealId: TypeRouteId, partId: TypeRouteId) => `${dealPath(dealId)}/parts?part_id=${partId}`,

  hsCodesPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/hs_codes`,
  hsCodesPartPath: (dealId: TypeRouteId, partId: TypeRouteId) => `${dealPath(dealId)}/hs_codes?part_id=${partId}`,
  hsCodesForShippingPath: (dealId: TypeRouteId, shippingId: string) =>
    `${dealPath(dealId)}/hs_codes?shipping_id=${shippingId}`,

  // payments
  paymentPath,
  newPaymentPath: (dealId: TypeRouteId) => `${paymentPath(dealId, 'new')}`,
  editPaymentPath: (dealId: TypeRouteId, paymentId: TypeRouteId) => `${paymentPath(dealId, paymentId)}/edit`,
  transferPaymentPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/transfer_payment`,

  // payouts
  payoutsPath,
  payoutPath,
  newPayoutPath: (dealId: TypeRouteId) => `${payoutsPath(dealId)}/new`,
  newPayoutRefundPath: (dealId: TypeRouteId) => `${payoutsPath(dealId)}/new?is_refund=true`,
  newManualPayoutPath: (dealId: TypeRouteId) => `${payoutsPath(dealId)}/new?manual=true`,
  newPayoutRefundPathWithReference: (dealId: TypeRouteId, reference: string) =>
    `${payoutsPath(dealId)}/new?is_refund=true&reference=${reference}`,
  editPayoutPath: (dealId: TypeRouteId, payoutId: TypeRouteId) => `${payoutPath(dealId, payoutId)}/edit`,

  creditNotePath: (dealId: TypeRouteId, resourceId: TypeRouteId) => `${dealPath(dealId)}/credit_notes/${resourceId}`,

  newOutcomingInvoicePath: (dealId: string) => `${dealPath(dealId)}/outcoming_invoices/new`,

  editOutcomingInvoicePath: (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
    `${outcomingInvoicePath(dealId, invoiceId)}/edit`,

  fixPublishedOutcomingInvoicePath: (dealId: string, invoiceId: string) =>
    `${outcomingInvoicePath(dealId, invoiceId)}/fix_published`,

  newProviderOrderPath: (dealId: TypeRouteId) => providerOrderPath(dealId, 'new'),

  editProviderOrderPath: (dealId: TypeRouteId, providerOrderId: TypeRouteId) =>
    `${providerOrderPath(dealId, providerOrderId)}/edit`,

  editPublishedOutcomingInvoicePath: (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
    `${outcomingInvoicePath(dealId, invoiceId)}/edit_published`,

  newDealIncomingInvoicePath: (dealId: TypeRouteId) => `${dealIncInvoicesPath(dealId)}/new`,

  editDealIncomingInvoicePath: (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
    `${incomingInvoicePath(dealId, invoiceId)}/edit`,

  newDealQualityControlPath: (dealId: TypeRouteId) => `${dealQualityControlsPath(dealId)}/new`,

  editDealQualityControlPath: (dealId: TypeRouteId, qcId: TypeRouteId) => `${qualityControlPath(dealId, qcId)}/edit`,

  editDealQualityControlPositionPath: (dealId: TypeRouteId, qcId: TypeRouteId, positionId: TypeRouteId) =>
    `${qualityControlPath(dealId, qcId)}/positions/${positionId}/edit`,

  newDealPartnerCreditNotePath: (dealId: TypeRouteId) => `${dealPartnerCreditNoteBasePath(dealId)}/new`,
  editDealPartnerCreditNotePath: (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
    `${dealPartnerCreditNotePath(dealId, invoiceId)}/edit`,

  newDealPartnerCreditNoteCorrectionPath: (dealId: TypeRouteId) =>
    `${dealPartnerCreditNoteCorrectionBasePath(dealId)}/new`,
  editDealPartnerCreditNoteCorrectionPath: (dealId: TypeRouteId, invoiceId: TypeRouteId) =>
    `${dealPartnerCreditNoteCorrectionPath(dealId, invoiceId)}/edit`,

  paymentOutcomingInvoiceRelationsTestPath: (dealId: TypeRouteId) => `${dealPath(dealId)}/payment_relations`,

  recentLeadsReviewPath,
  userProfilePath,

  rolesPath,

  messageFiltersPath,
  messageFiltersGroupPath,

  templatesPlaceholdersPath,

  dealQuotationPath,

  providersPath,
  providerPath,
  providerBalancePath,

  billingAccountPath,
  billingAccountBalancePath,
  shippingAddressPath,

  backofficeTrackingEventsPath: '/backoffice/package_tracking_events',
  /**
   * Only EU region can control tracking events
   * so we need to direct users from other regions to EU
   */
  backofficeTrackingEventsPathEU: `${ENV_APP_HOST_EU}/backoffice/package_tracking_events`,

  lookers,

  external,

  partners,

  dashboardOldProductionManagerPath: '/dashboard',
  dashboardProductionManagerPath: `${dashboardsPath()}production_manager`,
  dashboardDraftingEngineerPath: `${dashboardsPath()}drafting_engineer`,
  dashboardFinancePath: `${dashboardsPath()}finance`,
  dashboardSalesPersonPath: `${dashboardsPath()}sales_person`,
  dashboardRfqManagersPath: `${dashboardsPath()}rfq_managers`,

  logisticInvoicesPath,
  logisticInvoicePath,

  workspacesGeneralClaimsPath,
  workspacesGeneralEnquiriesPath,
  workspacesGeneralRfqsPath,
  workspacesGeneralRfqOffersPath,
  workspacesGeneralCouponsPath,
  workspacesGeneralJobsPath,
  workspacesGeneralJobOffersPath,
  workspacesGeneralIncomingInvoicesPath,
  workspacesGeneralOutcomingInvoicesPath,
  workspacesGeneralLogisticInvoicesPath,
  workspacesGeneralPartnersPath,
  workspacesGeneralPersonsPath,
  workspacesGeneralProviderOrdersPath,
  workspacesGeneralProductionSchedulesPath,
  workspacesGeneralOrganizationsPath,
  workspacesGeneralDealsPath,
  workspacesGeneralFrameworksPath,
  workspacesGeneralQualityControlsPath,
  workspacesGeneralShippingsPath,
  workspacesGeneralStoragesPath,
  workspacesGeneralBillingAccountsPath,
  workspacesGeneralShippingAddressesPath,
  workspacesGeneralPayoutsPath,
  workspacesGeneralPaymentsPath,
  workspacesGeneralBankPaymentsPath,
  workspacesGeneralBankPaymentsDraftPath,
  workspacesGeneralRefundsPath,
  workspacesGeneralUsersPath,
  workspacesGeneralOrganizationBalancesPath,
  workspacesGeneralTasksPath,
  workspacesGeneralMessageSubscriptionsPath,
  workspacesGeneralMessagesPath,
  workspacesGeneralEmailMessagesPath,
  workspacesGeneralHsCodesPath,

  workspacesLogisticsShippingsPath,
  workspacesRfqPartnersPath,
  workspacesDraftingDealPartsPath,
  workspacesDrawingsOrdersPath,
  workspacesDraftingEnquiryPartsPath,
  workspacesDrawingsQuotesPath,

  placingRepeatDeal,
  replacingRequest,
  placingIssues,

  warehouseIncoming: () => workspacesLogisticsShippingsPath() + '?preset=149',
  warehouseOutcoming: () => workspacesLogisticsShippingsPath() + '?preset=111',

  teamsPath,
  teamPath,

  activityLogPath,
};
